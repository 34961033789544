import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import Close from '@images/teachV2/close.svg'
import AddTag from '@images/teachV2/add-tag.svg'
import { Icon } from '@components/common'
import { apiCaller } from "@utils";
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom'

const WrapListTags = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  h1 {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    color: #101828;
  }
  .list-tags {
    display: flex;
    align-items: start;
    gap: 8px;
    flex-wrap: wrap;
    .no-tag {
      font-size: 14px;
      font-weight: 400;
      line-height: 33px;
    }
    .text-err {
      font-size: 12px;
      font-weight: 400;
      color: #D92D20;
    }
  }
  .btn-add {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    &:hover {
      div {
        color: #003C8F;
        svg {
          path {
            stroke: #003C8F;
          }
        }
      }
    }
  }
`

const WrapTag = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 5px 8px;
  background: #F9FAFB;
  border: 1px solid #D0D5DD;
  border-radius: 120px;
  .wrap-icon {
    padding: 2px;
    cursor: pointer;
    border-radius: 50%;
    &:hover {
      background: #475467;
      svg {
        path {
          stroke: #FFFFFF;
        }
      }
    }
    &.active {
      background: #475467;
    }
  }
  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: #344054;
  }
`

const WrapAddTag = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 8px;
  background: #F9FAFB;
  border: 1px solid #D0D5DD;
  border-radius: 120px;
  &.hide-element {
    opacity: 0;
  }
  &.tag-duplicate {
    border-color: #D92D20;
  }
  .input-tag {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.5px;
    padding: 0 4px;
    border: 0.5px solid #0089C2;
    background: #FFFFFF;
    border-radius: 4px;
    min-width: 100px;
  }
`

export default function ListTags(props) {
  const { dataQuestionDetail, setDataQuestionDetail, text, type } = props
  console.log(type);
  const [isVisible, setIsVisible] = useState(false);
  const [dataInput, setDataInput] = useState('');
  const history = useHistory()
  const refNewTag = useRef()
  
  const handleUpdateTags = (listTags) => {
    const params = {
      Action: "UpdateTags",
      QuestionItemId: dataQuestionDetail?.QuestionItemId,
      Tags: listTags
    }
    apiCaller(`/feedback/question/items/`, "POST", params).then((res) => {
      if (res?.status === 200) {
        const questionDetail = JSON.stringify({
          ...dataQuestionDetail,
          Tags: res?.data?.Tags
        })
        setDataQuestionDetail({
          ...dataQuestionDetail,
          Tags: res?.data?.Tags
        })
        history.push({
          pathname: text === "edit" ? `/teach/question-bank/edit` : `/teach/question-bank/review`,
          state: {
            question: questionDetail,
          },
        })
        refNewTag.current.innerText = ""
        setDataInput("")
      } else {
        toast.error("Failed to update tags", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setDataInput("")
      }
    });
  }

  const handleRemoveTag = (index) => {
    const newArr = [...dataQuestionDetail?.Tags.slice(0, index), ...dataQuestionDetail?.Tags.slice(index + 1)];
    handleUpdateTags(newArr)
  }

  const handleFocusAdd = () => {
    setIsVisible(!isVisible)
    refNewTag.current.innerText = ""
    refNewTag?.current?.focus()
  }

  return (
    <WrapListTags>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <h1>Tags</h1>
          {(!type || type === "review") && (
            <div className='btn-add ml-2' onClick={() => handleFocusAdd()}>
              <Icon src={AddTag} width={18} height={18}/>
            </div>
          )}
        </div>
        {/* {showSuccess && (
          <div className="check-success">
            <Icon src={check} />
          </div>
        )} */}
      </div>
      <div className="list-tags">
        {dataQuestionDetail?.Tags?.length > 0 ? dataQuestionDetail?.Tags?.map((tag, index) => (
          <WrapTag key={index}>
            <p>{tag}</p>
            {(!type || type === "review") && 
              <div
                className='wrap-icon'
                onClick={() => (!type || type === "review") ? handleRemoveTag(index) : null}
              >
                <Icon src={Close} width={14} height={14} stroke="#667085" />
              </div>
            }
          </WrapTag>
        )) : (
          <p className='no-tag'>No tags added</p>
        )}
        <div className='d-flex flex-column align-items-start'>
          <WrapAddTag className={`${isVisible ? "" : "hide-element"} ${dataQuestionDetail?.Tags?.includes(dataInput) ? "tag-duplicate" : ""}`}>
            <div
              contentEditable
              ref={refNewTag}
              className="input-tag"
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault(); // Chặn hành động mặc định khi nhấn Enter
                  refNewTag?.current?.innerText?.trim() !== "" && handleUpdateTags([...dataQuestionDetail?.Tags, refNewTag?.current?.innerText])
                }
              }}
              onInput={(e) => setDataInput(e.target.innerHTML)}
              onBlur={() => {
                setIsVisible(false)
                setDataInput("")
              }}
            >
            </div>
            <div className='wrap-icon'>
              <Icon src={Close} width={14} height={14} stroke="#667085" />
            </div>
          </WrapAddTag>
          {dataQuestionDetail?.Tags?.includes(dataInput) && <span className="text-err">This tag already exists.</span>}
        </div>
      </div>
    </WrapListTags>
  )
}
