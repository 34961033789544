import React from 'react';
import { useLocation } from 'react-router-dom';
import { NavigationComponent } from "@components/layouts";
import navigation from "@utils/navigation";

const MainContentContainer = ({ children, ...props }) => {
    // Destructure AccountData and navigator from props
    const { AccountData, navigator } = props;

    // Get the current location
    const location = useLocation();

    // Determine if the user is an instructor
    // navigator true thì mới hiển thị menu
    const IsInstructor = AccountData?.is_instructor === 'Yes' && navigator;

    // Check if the URL contains '/teach/module/'
    const isTeachModulePathV2 = location.pathname.includes('/teach/module/');

    // Choose the correct menu based on the 'view' parameter and the URL path
    // Check sử dụng menu cho v2 hoặc v1
    const mainMenu = isTeachModulePathV2 ? navigation?.mainMenuTeachV2 : navigation?.mainMenuTeach;
    return (
        <div className="main_content_iner">
            {IsInstructor && <NavigationComponent {...props} data={mainMenu} isInstructorViewV2={isTeachModulePathV2} />}
            <div className={`mw-container-auto`}>
                {children}
            </div>
        </div>
    );
};

export default MainContentContainer;