import React, { useState } from 'react'
import styled from 'styled-components'
import { Input } from 'reactstrap';
import { ButtonComponent } from "@components/patterns/elements/Button";
import { useForm } from "react-hook-form";
import { apiCaller } from "@utils";
import { toast } from 'react-toastify';
import { Rating as RatingComponent } from '@ui-partents/Rating';
// import { Icon } from '@components/common'
// import Edit from '@images/teachV2/edit.svg'
import { Rating } from 'react-simple-star-rating'

const fillColorArray = [
  "#f17a45",
  "#f19745",
  "#f1a545",
  "#f1b345",
  "#f1d045"
];

const WrapFeedback = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: calc(100vh - 120px);
  overflow-y: scroll;
  padding-right: 8px;
  padding-left: 2px;
  padding-bottom: 20px;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #D6DBE3; 
    border-radius: 8px;
  }
`

const TitleFeedback = styled.h1`
  font-size: 20px;
  font-weight: 600;
`

const WrapRating = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .rate-note {
    font-size: 16px;
    font-weight: 400;
  }
`

const FormRating = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  h1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    white-space: nowrap;
  }
`

const FormFeedback = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const ResultFeedback = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
`

export default function FormFeedbackAndRating(props) {
  const { dataQuestionDetail, setDataQuestionDetail } = props
  const [, setRating] = useState(0);

  const {
    handleSubmit,
    setValue,
    watch,
  } = useForm()
  
  const handleRating1 = (rate) => {
    setRating(rate);
    setValue('rate', rate)
  }

  const onSubmit = (data) => {
    const params = {
      Action: "SubmitQuestionBankItemReview",
      QuestionItemId: dataQuestionDetail?.QuestionItemId,
      Stars: data?.rate,
      Comments: data?.suggestion
    };
    apiCaller(`/feedback/question/items/`, "POST", params).then((res) => {
      if (res?.status === 200) {
        toast.success("Review Successfully!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        const dataReview = res?.data?.filter((review) => review?.QuestionItemId === dataQuestionDetail?.QuestionItemId)
        setDataQuestionDetail((prev) => ({
          ...dataQuestionDetail,
          ReviewData: dataReview[0]?.ReviewData
        }))
      } else {
        toast.error("Failed to feedback", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <WrapFeedback>
        <WrapRating>
          <TitleFeedback>Feedback & Rating</TitleFeedback>
          <p className="rate-note">Please kindly review this question and provide your rating and feedback to help improve it.</p>
        </WrapRating>
        {dataQuestionDetail?.ReviewData ? (
          <ResultFeedback>
            <div className='d-flex align-items-center justify-content-between'>
              <RatingComponent width={18} rating={dataQuestionDetail?.ReviewData?.Stars} />
              {/* <div>
                <Icon src={Edit} width={16} height={20} stroke="#475467"/>
              </div> */}
            </div>
            <p>{dataQuestionDetail?.ReviewData?.Comments}</p>
          </ResultFeedback>
        ) : (
          <>
            <FormRating>
              <h1>Rate this Question</h1>
              <div className="d-flex align-items-center">
                <Rating
                  onClick={handleRating1}
                  size={36}
                  transition
                  fillColorArray={fillColorArray}
                  // readonly={!dataQuestionDetail?.Contents?.answer_choices}
                />
              </div>
            </FormRating>
            <FormFeedback>
              <Input 
                name="suggestion" 
                type="textarea" 
                placeholder="What could be improved in this question? Any suggestions?" 
                style={{ height: '180px' }}
                onChange={(e) => setValue('suggestion', e.target.value)}
                // disabled={!dataQuestionDetail?.Contents?.answer_choices}
              />
              <div className='d-flex justify-content-end'>
                <ButtonComponent 
                  className="px-4"
                  disabled={(!watch('suggestion') || !watch('rate'))}
                >
                  Send Review
                </ButtonComponent>
              </div>
            </FormFeedback>
          </>
        )}
      </WrapFeedback>
    </form>
  )
}
