import React, { useState } from "react";
import ModalMoveToFolder from "./ModalMoveToFolder";

const WrapModalMoveToFolder = ({ children, ...props}) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () =>  {
    setOpen(true)
  }

  const handleClose = () => setOpen(false)

  return (
    <>
      {children({ onOpen: handleOpen })}
      {open && 
        <ModalMoveToFolder
          isOpen={open}
          toggle={handleClose}
          {...props}
        >
        </ModalMoveToFolder>
      }
    </>
  )
}

export default WrapModalMoveToFolder
