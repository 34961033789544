import React from 'react'
import { TableAllReviews } from './index';

const FinalBank = (props) => {

  return (
    <TableAllReviews {...props}/>
  )
}

export default FinalBank
