import React, { useState, useRef, useEffect } from "react";
import styled from 'styled-components';
import { EngageFreeText } from "@components/EngageApp/FreeText";
// import { EngageChatVoice } from "@components/EngageApp/ChatVoice";
import { EngageRealtimeVoice } from "@components/EngageApp/ChatVoice";
import imgBackground from '@images/chatvoice/bg-engage-footer.svg';
import { LoaderText } from "@utils";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { apiCaller } from "@utils";
import { engageConstants } from "@constants";

// Styled component for the wrapper
const Wrapper = styled.div`
    background-image: url(${imgBackground});
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    z-index: -1;
`;

function EngagePage(props) {
    let { id } = useParams();
    let intervalIDRef = React.useRef(null);
    const dispatch = useDispatch();

    const [resChatData, setData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isStreaming, setStreaming] = useState(false); // Thêm state cho loading khi stream
    const messagesEndRef = useRef(null);
    const [contentStream, setStream] = useState(null);
    const [contentShowFirst, setContentShowFirst] = useState(null);
    const [contentSpeechAudio, setContentSpeechAudio] = useState(null);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            messagesEndRef?.current?.scrollTo(0, messagesEndRef.current.scrollHeight);
        }, 100);

        return () => clearTimeout(timeoutId);
    }, [resChatData]);

    useEffect(() => {
        const fetchData = () => {
            const params = {
                "action_type": "create_engage",
                "topic_id": id
            }
            setLoading(true);
            dispatch({ type: engageConstants.ENGAGE_REQUEST, isLoading });
            apiCaller(`/api/engage/functional/`, 'POST', params).then(res => {
                let data = res?.data;
                if (res?.status === 200) {
                    setData(data);

                    const dialogFirst = data?.dialog[0]?.content;
                    setContentShowFirst(dialogFirst);

                    dispatch({ type: engageConstants.ENGAGE_SUCCESS, payload: data });
                    messagesEndRef?.current?.scrollTo(0, messagesEndRef.current.scrollHeight);
                } else {
                    dispatch({
                        type: engageConstants.ENGAGE_FAILURE,
                        error: "error",
                    });
                    console.log("error");
                }
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            });
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleContentForSpeech = (responseConversation) => {
        setContentSpeechAudio(responseConversation?.response);
    }

    const handleStreamData = (dataPromise) => {
        const params = {
            "action_type": "get_topic_prompt",
            "engage_id": resChatData?.engage_id
        }
        setStreaming(true); // Bắt đầu stream, bật loading
        Promise.race([dataPromise, new Promise(resolve => setTimeout(resolve, 3000, 'timeout'))]).then(result => {
            if (result !== 'timeout') {
                console.log('API question returned before timeout');
                setStreaming(false); // Tắt loading khi kết thúc stream
                return;
            }

            console.log('API question timed out. Calling API stream_data...');
            intervalIDRef.current = setInterval(() => {
                apiCaller(`/api/engage/functional/`, 'POST', params).then(res => {
                    const dataStream = res?.data;
                    if (res?.status === 200) {
                        setStream(dataStream);
                        messagesEndRef?.current?.scrollTo(0, messagesEndRef.current.scrollHeight);
                    } else {
                        console.log("error....");
                        clearInterval(intervalIDRef.current);
                        intervalIDRef.current = null;
                    }
                });
            }, 1000);
        }).finally(() => {
            setStreaming(false); // Tắt loading khi kết thúc stream
        });
    }
    
    const renderInteractionComponent = () => {
        if (resChatData?.interaction_method === 'Voice') {
            return (
                // <EngageChatVoice
                //     {...props}
                //     resChatData={resChatData}
                //     handleContentForSpeech={handleContentForSpeech}
                //     isLoadingChatVoice={isLoading}
                //     setLoadingChatVoice={setLoading}
                //     contentSpeechAudio={contentSpeechAudio}
                //     contentShowFirst={contentShowFirst}
                //     handleResetContentFirst={() => setContentShowFirst(null)}
                //     id={id}
                // />
                <EngageRealtimeVoice
                    {...props}
                    resChatData={resChatData}
                    handleContentForSpeech={handleContentForSpeech}
                    isLoadingChatVoice={isLoading}
                    setLoadingChatVoice={setLoading}
                    contentSpeechAudio={contentSpeechAudio}
                    contentShowFirst={contentShowFirst}
                    handleResetContentFirst={() => setContentShowFirst(null)}
                    id={id}
                />
            );
        } else {
            return (
                <EngageFreeText
                    {...props}
                    resChatData={resChatData}
                    setData={setData}
                    messagesEndRef={messagesEndRef}
                    intervalIDRef={intervalIDRef}
                    contentStream={contentStream}
                    setStream={setStream}
                    handleStreamData={handleStreamData}
                    isStreaming={isStreaming}
                />
            );
        }
    }

    return (
        <Wrapper>
            <Helmet>
                <title>{resChatData?.topic_name}</title>
            </Helmet>
            {isLoading ? <LoaderText /> : (
                <React.Fragment>
                    {renderInteractionComponent()}
                </React.Fragment>
            )}
        </Wrapper>
    );
}

export default EngagePage;