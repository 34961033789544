import React, { useState } from 'react'
import styled from 'styled-components'
import { Icon } from '@components/common'
import Down from '@images/teachV2/chevron-down.svg'
import Up from '@images/teachV2/chevron-up.svg'
import Folder from '@images/teachV2/folder.svg'
import { Col, Row } from 'reactstrap'

const WrapListFolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 12px;
  .folder-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-left: 12px;
  }
`

const ItemFolder = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 16px;
  border-radius: 6px;
  border: 1px solid #D0D5DD;
  background-color: ${({ active }) => active ? '#F2F4F7' : '#FFF'};
  cursor: pointer;
  p {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #344054;
    width: 100%;
  }
`

const ListFolderItem = (props) => {
  const { activeFolder, setActiveFolder, listFolders } = props;
  const [showFolder, setShowFolder] = useState(true)

  const handleShowData = () => {
    setShowFolder(!showFolder)
  }

  return (
    <WrapListFolder>
      <div className='d-flex align-items-center'>
        <div onClick={handleShowData}>
          {showFolder ? <Icon stroke="#1D2939" src={Up}/> : <Icon stroke="#1D2939" src={Down}/>}
        </div>
        <p className="folder-title">Folders</p>
      </div>
      {showFolder && (
        <Row className='m-0'>
          {listFolders?.map((folder, index) => (
            <Col xs={3} className="px-2 mb-3">
              <ItemFolder 
                key={index} 
                onClick={() => setActiveFolder(folder)}
                active={activeFolder?.FolderId === folder?.FolderId}
              >
                <Icon src={Folder}/>
                <p>{folder?.FolderName}</p>
                {/* <Icon src={Lock}/> */}
              </ItemFolder>
            </Col>
          ))}
        </Row>
      )}
    </WrapListFolder>
  )
}

export default ListFolderItem
