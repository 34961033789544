import React, { useRef, useEffect } from "react";
import ReactPlayer from "react-player";

function PatientImageBackground(props) {
    const { caseData, checkImageLoad, stateType, sizePatientPhoto } = props;

    const dataResponse = caseData?.data;
    const dataInterface = dataResponse?.Case?.Interface;

    const PatientImage = dataInterface?.PatientImage;
    const PatientProfile = dataInterface?.PatientProfile;

    const ProfileType = PatientProfile?.ProfileType;
    const PatientProfileURL = PatientProfile?.ProfileFileURL;
    const PatientProfieData = PatientProfile?.ProfileData;

    const imageRef = useRef(null);

    useEffect(() => {
        if (imageRef.current && imageRef.current.complete) {
            // Hình đã load sẵn trước khi render component
            const { width, height } = imageRef.current;
            checkImageLoad(width, height);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleImageLoad = () => {
        if (imageRef.current && imageRef.current.complete) {
            // Hình đã load sẵn trước khi render component
            const { width, height } = imageRef.current;
            checkImageLoad(width, height);

        }
    };

    const shouldDisplayVideo = ProfileType === "Video" && !stateType?.exam && PatientProfileURL;     //(1)
    const shouldDisplayIframe = ProfileType === "Embed" && PatientProfieData;     //(2)
    const shouldDisplayImage = ProfileType === "Image" || (ProfileType === "Video" && stateType?.exam); //(3)

    return (
        <React.Fragment>
            {shouldDisplayVideo && (
                <ReactPlayer
                    url={PatientProfileURL}
                    className="react-player"
                    width="100%"
                    height="100%"
                    controls={true}
                />
            )}

            {shouldDisplayIframe && (
                <div dangerouslySetInnerHTML={{__html: shouldDisplayIframe}} />
            )}

            <img
                className="img-fluid"
                src={PatientImage}
                alt="exam_photo"
                ref={imageRef}
                onLoad={handleImageLoad}
                style={{ display: sizePatientPhoto?.width > 0 ? 'none' : 'block' }}
            />

            {shouldDisplayImage && (
                <img
                    className="img-fluid"
                    src={PatientImage}
                    alt="patient"
                />
            )}
        </React.Fragment>
    );
}

export default PatientImageBackground;