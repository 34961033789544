import React, { useState, useEffect, useRef } from "react";
import styled, { css, keyframes } from "styled-components";

// image
import image from "@images/common/assistant.png";

// Icons
import IconMicro from '@images/chatvoice/micro-icon.svg';
import IconPause from '@images/chatvoice/pause-icon.svg';

// components
import { apiCaller } from "@utils";

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
`;

const ImageLabelWrapper = styled.div`
  text-align: center;
  width: 100%;
`;

const pulse = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
    border: 65px solid #2268F0;
  }
  50% {
    border: solid #2268F0;
    opacity: 0.8;
  }
  90% {
    transform: scale(3.2);
    opacity: 0.2;
    border: 3px solid #2268F0;
  }
  100% {
    transform: scale(3.3);
    opacity: 0;
    border: 1px solid #2268F0;
  }
`;

// Keyframes for shake animation
const shake = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-4px);
  }
  50% {
    transform: translateX(4px);
  }
  75% {
    transform: translateX(-4px);
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  margin-bottom: 80px;
  .object {
    display: flex;
    flex: 0 1 100%;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    position: relative;
  }
  .outline {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 40px solid #2268F0;
    position: absolute;
    @media (max-width: 768px) {
      width: 30px;
      height: 30px;
    }
    ${({ isRecording }) =>
    isRecording &&
    css`
        animation: ${pulse} 2s ease-out infinite;
      `}
  }
  .outline-delayed {
    ${({ isRecording }) =>
    isRecording &&
    css`
        animation-delay: 1.5s;
        animation: ${pulse} 2s ease-out infinite;
      `}
  }
  .button {
    width: 118px;
    height: 118px;
    border-radius: 50%;
    background: #2268F0;
    opacity: 0.34;
    cursor: pointer;
    transition: transform 0.2s;
    @media (max-width: 768px) {
      width: 80px;
      height: 80px;
    }
    &:active {
      transform: scale(0.95);
      animation: ${shake} 0.3s linear;
    }
  }
  .button-circle-in {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #2268F0;
    position: absolute;
    @media (max-width: 768px) {
      width: 90px;
      height: 90px;
    }
  }
  .icon-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 52px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      width: 32px;
      height: 32px;
    }
  }
  .icon {
    max-width: 100%;
    max-height: 100%;
  }
`;

const ImageBox = styled.div`
  margin-top: 60px;
  background: #6fa9e9;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

const BlinkingDiv = styled.div`
  position: relative;
  ${({ audioStarted }) =>
    audioStarted &&
    css`
      &:before,
      &:after {
        content: "";
        width: 120px;
        height: 120px;
        border: 9px solid rgba(20, 91, 173, 0.8);
        display: block;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        @media (max-width: 768px) {
          width: 100px;
          height: 100px;
        }
      }
      &:before {
        animation: hWnWDR 0.75s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
      }
      &:after {
        animation: hWnWDR 0.75s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
        animation-delay: 0.375s;
      }

      @keyframes hWnWDR {
        from {
          transform: translate(-50%, -50%) scale(1);
          opacity: 0.8;
        }
        to {
          transform: translate(-50%, -50%) scale(1.2);
          opacity: 0.4;
        }
      }
    `}
`;

const FrameChatFullBox = styled.div`
  height: calc(100vh - 80px);
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
`;

const Text = styled.div`
  color: #081B2A;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 15px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

function EngageRealtimeVoice(props) {
  const { id } = props;
  const [instructions, setIinstructions] = useState("");
  const [loadingSpeech, setLoadingSpeech] = useState(false);
    // const [isSessionActive, setIsSessionActive] = useState(false);
    // const [events, setEvents] = useState([]);
    const [dataChannel, setDataChannel] = useState(null);
    const peerConnection = useRef(null);
    const audioElement = useRef(null);

  useEffect(() => {
    const params = {
      action_type: "get_topic_prompt",
      topic_id: id,
    };
    apiCaller(`/api/engage/functional/`, "POST", params).then((res) => {
      let data = res?.data;
      setIinstructions(data?.system_prompt);
    });
  }, [id]);

  const handleClick = async () => {
    // Get an ephemeral key from the Fastify server
    // const tokenResponse = await getToken();
    setLoadingSpeech(true);
    const params = {
      instructions: instructions,
      model: "gpt-4o-realtime-preview",
      voice: "verse"
    }
    apiCaller(`/api/binh/realtime_session/`, "POST", params).then(async (res) => {
      if (res?.status === 200) {
        const data = res?.data;
        // const data = await tokenResponse.json();
        console.log(data);
        const EPHEMERAL_KEY = data.client_secret.value;
    
        // Create a peer connection
        const pc = new RTCPeerConnection();
    
        // Set up to play remote audio from the model
        audioElement.current = document.createElement("audio");
        audioElement.current.autoplay = true;
        pc.ontrack = (e) => (audioElement.current.srcObject = e.streams[0]);
    
        // Add local audio track for microphone input in the browser
        const ms = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        pc.addTrack(ms.getTracks()[0]);
    
        // Set up data channel for sending and receiving events
        const dc = pc.createDataChannel("oai-events");
        setDataChannel(dc);
    
        // Start the session using the Session Description Protocol (SDP)
        const offer = await pc.createOffer();
        await pc.setLocalDescription(offer);
    
        const baseUrl = "https://api.openai.com/v1/realtime";
        const model = "gpt-4o-realtime-preview";
        const sdpResponse = await fetch(`${baseUrl}?model=${model}`, {
          method: "POST",
          body: offer.sdp,
          headers: {
            Authorization: `Bearer ${EPHEMERAL_KEY}`,
            "Content-Type": "application/sdp",
          },
        });
    
        const answer = {
          type: "answer",
          sdp: await sdpResponse.text(),
        };
        await pc.setRemoteDescription(answer);
    
        peerConnection.current = pc;
      } 
    });
  }

  function stopSession() {
    if (dataChannel) {
      dataChannel.close();
    }
    if (peerConnection.current) {
      peerConnection.current.close();
    }

    setLoadingSpeech(false);
    setDataChannel(null);
    peerConnection.current = null;
  }

  return (
    <FrameChatFullBox className="frameChat__fullBox">
      <div className="h-100 w-100 text-center d-flex flex-column justify-content-between">
        <ImageLabelWrapper>
          <BlinkingDiv audioStarted={loadingSpeech}>
            <ImageBox>
              <StyledImage src={image} alt="Patient" className={`img-fluid`} />
            </ImageBox>
          </BlinkingDiv>
        </ImageLabelWrapper>
        {loadingSpeech ? (
          <Box
            isRecording={loadingSpeech}
            isLoading={loadingSpeech}
            onClick={stopSession}
          >
            <div className="object">
              <div className="outline" />
              <div className="outline outline-delayed" />
              <div className="button" />
              <div className="button button-circle-in" />
              <div className="icon-wrapper">
                <img src={IconPause} alt={'Stop Recording'} className="icon" />
              </div>
            </div>
            <Text>{(loadingSpeech) && 'Stop talking'}</Text>
          </Box>
        ) : (
          <Box
            isRecording={loadingSpeech}
            isLoading={loadingSpeech}
            onClick={handleClick}
          >
            <div className="object">
              <div className="outline" />
              <div className="outline outline-delayed" />
              <div className="button" />
              <div className="button button-circle-in" />
              <div className="icon-wrapper">
                <img src={IconMicro} alt={'Start Recording'} className="icon" />
              </div>
            </div>
            <Text>{(loadingSpeech) ? '' : 'Press to Talk'}</Text>
          </Box>
        )}
      </div>
    </FrameChatFullBox>
  );
}

export default EngageRealtimeVoice;
