import React, { createRef, useRef } from 'react'
import styled from 'styled-components'
import ItemResult from './ItemResult'
import { apiCaller } from "@utils";
import { toast } from 'react-toastify';
import { ButtonComponent } from "@components/patterns/elements/Button";
import { useHistory } from 'react-router-dom';

const WrapResult = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  /* height: calc(100vh - 120px);
  overflow-y: scroll;
  padding-right: 8px;
  padding-bottom: 20px;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #D6DBE3; 
    border-radius: 8px;
  } */

  .question-result {
    display: flex;
    flex-direction: column;
    gap: 16px;
    &__question {
      h1 {
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        margin-bottom: 12px;
      }
      p {
        font-size: 16px;
        font-weight: 600;
        border-radius: 6px;
        border: 1px solid #D0D5DD;
        padding: 12px;
        &:hover {
          background: #F2F4F7;
        }
        &:focus {
          box-shadow: 0px 0px 0px 2px #006E9B1F;
          border: 1px solid #0089C2;
        }
      }
    }
    &__list {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  .question-explanation {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    &:hover {
      background: #F2F4F7;
    }
    &:focus,
    &:focus-within {
      box-shadow: 0px 0px 0px 2px #006E9B1F;
      border: 1px solid #0089C2;
    }
    p {
      color: #101828;
      font-size: 16px;
      font-weight: 700;
    }
    span {
      color: #1D2939;
      font-size: 16px;
      font-weight: 400;
      padding: 4px;
      border-radius: 8px;
      border: 1px solid transparent;
    }
  }
`

export default function ResultQuestionBank(props) {
  const { dataQuestionDetail, type } = props
  const history = useHistory()
  const refQuestion = useRef()
  const refExplanation = useRef()
  let lineRefs = useRef([]);
  lineRefs.current = dataQuestionDetail?.Contents?.multiple_choices?.length > 0 && dataQuestionDetail?.Contents?.multiple_choices?.map((_, i) => lineRefs.current[i+1] ?? createRef());

  const handleUpdate = () => {
    const answerChoices = dataQuestionDetail?.Contents?.multiple_choices?.map((choice, index) => {
      return {
        id: choice.id,
        content: lineRefs?.current[index]?.current?.innerText
      }
    })
    const params = {
      "action_type": "update_question_bank",
      "id": dataQuestionDetail?.QuestionItemId,
      "contents": {
        ...dataQuestionDetail?.Contents,
        "question": refQuestion?.current?.innerText,
        "multiple_choices": answerChoices,
        "best_answer": {
          ...dataQuestionDetail?.Contents?.best_answer,
          "explanation": refExplanation?.current?.innerText,
        }
      }
    }
    apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
      if (res.status === 200) {
        const questionDetail = JSON.stringify({
          ...dataQuestionDetail,
          Contents: res?.data?.contents
        })
        history.push({
          pathname: `/teach/question-bank/edit`,
          state: {
            question: questionDetail,
          },
        })
        toast.success("Successfully updated question!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("Fail to update this question!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }


  return (
    <WrapResult>
      <div className="question-result">
        <div className="question-result__question">
          <h1>Question</h1>
          <p contentEditable={(type === "org" || type === "review" || type === "final") ? false : true} ref={refQuestion}>{dataQuestionDetail?.Contents?.question}</p>
        </div>
        <div className="question-result__list">
          {dataQuestionDetail?.Contents?.multiple_choices?.map((choice, i) => (
            <ItemResult
              key={i}
              refAnswer={lineRefs.current[i]}
              choice={choice} 
              question={dataQuestionDetail?.Contents}
              {...props}
            />
          ))}
        </div>
      </div>
      <div className="question-explanation">
        <p>Overall Explanation: 
          <span contentEditable={(type === "org" || type === "review" || type === "final") ? false : true} ref={refExplanation} dangerouslySetInnerHTML={{ __html: dataQuestionDetail?.Contents?.best_answer?.explanation || "" }} />
        </p>
      </div>
      {(type !== "org" && type !== "review" && type !== "final") && (
        <div className='d-flex justify-content-end'>
          <ButtonComponent  onClick={() => handleUpdate()} className="px-4" size="small">Save</ButtonComponent>
        </div>
      )}
    </WrapResult>
  )
}
