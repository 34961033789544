import React, { useEffect } from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import { LoaderText } from "@utils";

//ui-partents
import { MessageEmtyData } from "@ui-partents/Message";
import { SearchCustomForTable } from "@ui-partents/Search";

// Import the pagination configuration
import {paginationOptions} from "@utils";
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { getListQuestionBankToReview } from "@actions"
import { TagsToolTip } from "@ui-partents/Tags";

import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { teachV2Constants } from "@constants";

const TextDetail = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #0089C2;
  cursor: pointer;  
`

const TableToBeReviewed = (props) => {
  const { setListValue, inputValue, setInputValue, listValue, isQbankAdmin, isQbankReviewer } = props;
  const dispatch = useDispatch();
  const listQuestionBank = useSelector((state) => state.getListQuestionBankToReview || []);
  const history = useHistory()

  useEffect(() => {
    dispatch(getListQuestionBankToReview({
      Action: "LoadQuestionBankItemsToReview"
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (listQuestionBank?.data) {
      dispatch({ type: teachV2Constants.FILTER_LIST_QUESTION_BANK_REVIEW_SUCCESS, payload: listQuestionBank?.data, params: listValue });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listValue])

  const componentsCommon = {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
    MultiValueContainer: (props) => {
      return (
        <div className="custom-tag">
          <components.MultiValueContainer {...props} />
        </div>
      );
    }
  };

  const handleAddTag = (value) => {
    if (value?.length === 0) {
      dispatch(getListQuestionBankToReview({
        Action: "LoadQuestionBankItemsToReview"
      }))
    }
    setListValue(value)
  }

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setListValue((prev) => prev ? [...prev, createOption(inputValue)] : [createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const handleBlur = () => {
    if (inputValue) {
      setListValue((prev) => prev ? [...prev, createOption(inputValue)] : [createOption(inputValue)]);
    }
  }

  const columns = [
    {
      dataField: "title",
      text: "Question",
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            {row?.Contents?.question}
          </div>
        );
      },
      filterValue: (cell, row) => row.Contents.question
    },
    {
      dataField: "Owner",
      text: "Owner",
      headerStyle: { width: "190px" },
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            {cell || "med2lab"}
          </div>
        );
      },
    },
    {
      Field: "tags",
      text: "Tag(s)",
      headerClasses: "align-middle text-center",
      headerStyle: { width: "168px" },
      formatter: (cell, row) => {
        return (
          <TagsToolTip
            tags={row?.Tags}
            documentId={row?.QuestionItemId}
            color={'blue'}
          />
        );
      },
    },
    // {
    //   dataField: "QuestionType",
    //   text: "Status",
    //   headerStyle: { width: "190px" },
    //   formatter: (cell, row) => {
    //     return (
    //       <TagsText content="Reviewed" />
    //     );
    //   },
    //   filterValue: (cell, row) => row.Contents.question
    // },
    {
      dataField: "QuestionType",
      text: "Action",
      headerStyle: { width: "190px" },
      formatter: (cell, row) => {
        return (
          <div className={`d-flex align-items-center`}>
            <TextDetail onClick={() => { 
              const questionDetail = JSON.stringify(row)
              history.push({
                pathname: `/teach/question-bank/review`,
                state: {  // location state
                  question: questionDetail, 
                  type: "review",  
                  permission: isQbankAdmin || isQbankReviewer,
                },
              })
            }}>
              Review
            </TextDetail>
          </div>
        );
      },
      filterValue: (cell, row) => row.Contents.question
    },
  ];

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <React.Fragment>
      <ToolkitProvider
        keyField="QuestionItemId"
        data={listQuestionBank?.dataFilter || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <div>
            <div className="table-bank__filter d-flex align-items-center justify-content-between">
              <div className='tb-heading-total'>Total Questions: {listQuestionBank?.dataFilter?.length}</div>
              <div className='d-flex align-items-center'>
                <div className="boxSearch">
                  <SearchCustomForTable
                    toolkitprops={toolkitprops}
                    paginationProps={paginationProps}
                    isResetPagination
                    searchMode="auto"
                    placeholder={`Search`}
                  />
                </div>
                <div className="email-share__form-input ml-2">
                  <CreatableSelect
                    components={componentsCommon}
                    inputValue={inputValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={(newValue) => {
                      handleAddTag(newValue)
                    }
                    }
                    onInputChange={(newValue) => {
                      setInputValue(newValue)
                    }}
                    onKeyDown={(e) => {
                      paginationProps.onPageChange(1, 10);
                      handleKeyDown(e)
                    }}
                    onBlur={handleBlur}
                    placeholder="Filter by Tags"
                    value={listValue}
                    classNamePrefix={`filter-tag`}
                  />
                </div>
              </div>
            </div>
            <BootstrapTable
              condensed
              wrapperClasses="table-custom-common table-responsive mt-4 pb-5"
              bordered={false}
              data={listQuestionBank?.dataFilter || []}
              columns={columns}
              // selectRow={selectRow}
              noDataIndication={() => (
                <MessageEmtyData 
                  title="No Results Found"
                  text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                />
              )}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      <div className="pagination-custom-case d-flex justify-content-end ml-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </React.Fragment>
  );

  const totalSize = listQuestionBank?.dataFilter?.length || 0;  // Determine the total size dynamically
  // Generate pagination configuration dynamically
  const paginationConfig = paginationOptions({ totalSize });
  return (
    <div className='table-bank position-relative'>
      {listQuestionBank?.isLoading ? (
        <div className="height-loader mt-5">
          <LoaderText />
        </div>
        ) : (
        listQuestionBank?.dataFilter?.length === 0 && listValue?.length === 0 ? (
          <MessageEmtyData 
            title="No Questions Available Yet"
            text="Please check back later for updates!"
          />
        ) : (
          <PaginationProvider pagination={paginationFactory(paginationConfig)}>
            {contentTable}
          </PaginationProvider>
        )
      )}
    </div>
  )
}

export default TableToBeReviewed
