import React from 'react'
import styled from 'styled-components'
import { Rating as RatingComponent } from '@ui-partents/Rating';
import { Rating } from 'react-simple-star-rating'

const WrapFeedback = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: calc(100vh - 120px);
  overflow-y: scroll;
  padding-right: 8px;
  padding-left: 2px;
  padding-bottom: 20px;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #D6DBE3; 
    border-radius: 8px;
  }
`

const TitleFeedback = styled.h1`
  font-size: 20px;
  font-weight: 600;
`

const WrapRating = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 0 16px;
  border-bottom: 1px solid #D0D5DD;
  .list-stars {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .rate-percent {
    font-size: 16px;
    font-weight: 600;
  }
  .rate-number {
    font-size: 16px;
    font-weight: 400;
  }
`

const ContentFeedback = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: 1px solid #D0D5DD;
  .rate-info {
    &__user {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    &__percent {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
  .rate-content {
    font-size: 14px;
    font-weight: 400;
    color: #323232;
  }
  .rate-more {
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    color: #0089C2;
  }
  &:last-child {
    border: none;
  }
`

export default function FeedbackAndRating(props) {
  const { dataQuestionDetail } = props
  return (
    <WrapFeedback>
      <TitleFeedback>Feedback & Rating</TitleFeedback>
      <WrapRating>
        <div className="list-stars">
          <Rating 
            readonly={true} 
            initialValue={dataQuestionDetail?.AverageStar} 
            allowFraction
          />
        </div>
        <p className="rate-percent">{dataQuestionDetail?.AverageStar || 0}</p>
        <p className="rate-number">({dataQuestionDetail?.ReviewData?.length || 0} Ratings)</p>
      </WrapRating>
      {dataQuestionDetail?.ReviewData?.length > 0 && dataQuestionDetail?.ReviewData.map((item, index) => (
        <ContentFeedback key={index}>
          <div className="rate-info d-flex align-items-center justify-content-between">
            <div className="rate-info__user">
              <h1>Anonymous</h1>
              {/* <span>.</span>
              <p>22 Jul</p> */}
            </div>
            <div className="rate-info__percent">
              <RatingComponent rating={item?.Stars} />
            </div>
          </div>
          <p className="rate-content">{item?.Comments}</p>
        </ContentFeedback>
      ))}
    </WrapFeedback>
  )
}
