import React from 'react'
import styled from 'styled-components'
import { Col, Container, Row } from 'reactstrap'
import { ResultQuestionBank } from './ResultQuestionBank'
import { FeedbackAndRating } from './FeedbackAndRating'
import { ListTags } from './ListTagsQuestion'
// import { FooterFinalBank } from './FooterQuestionBank'
// import FooterQuestionBank from './FooterQuestionBank/FooterQuestionBank'

const WrapTagsAndFeedback = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  hr {
    margin: 0;
  }
`

export default function QuestionBankEditView(props) {
  const { type } = props

  return (
    <Container>
      <div className="result-top">
        <Row>
          <Col md={8} className='px-3'>
            <ResultQuestionBank {...props}/>
          </Col>
          <Col md={4} className='p-0'>
            <WrapTagsAndFeedback>
              <ListTags
                text="edit"
                {...props}
              />
              <hr />
              {type === "org" && <FeedbackAndRating {...props}/>}
            </WrapTagsAndFeedback>
          </Col>
        </Row>
      </div>
      {/* {type === "org" && permission && (
        <div id="result-bottom">
          <Container>
            <Row>
              <Col md={12}>
                <FooterFinalBank  {...props}/>
              </Col>
            </Row>
          </Container>
        </div>
      )} */}
    </Container>
  )
}
