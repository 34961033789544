import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const InputContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    flex: 1;
    max-width: ${(props) => props.maxWidth || "none"};
`;

const Input = styled.input`
    flex: 1;
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    color: #101828;
    padding: 8px 12px;
    height: 36px;
    outline: none;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-overflow: ellipsis;
    padding-left: 36px;
    padding-right: 36px;
    &:focus {
        border-color: #0089C2;
        box-shadow: 0px 0px 0px 4px #006E9B1F;
    }
`;

const SearchIcon = styled.svg`
    position: absolute;
    left: 12px;
    width: 20px;
    height: 20px;
    fill: none;
`;

const ClearButton = styled.button`
    position: absolute;
    right: 8px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
`;

const ClearIcon = styled.svg`
    width: 20px;
    height: 20px;
`;

const SearchButton = styled.button`
    margin-left: 12px;
    display: flex;
    align-items: center;

    .spinner-border {
        margin-right: 8px;
    }
`;

const SearchCustomForTable = ({
    toolkitprops,
    searchMode = "button", // "button" or "auto"
    maxWidth,
    placeholder,
    textButton,
    onClear, // Callback cho nút clear
    onChange, // Callback khi input thay đổi
    loading,
    paginationProps,
    isResetPagination,
}) => {
    const inputRef = useRef();
    const [inputValue, setInputValue] = useState("");

    // Effect để xử lý auto search (nếu searchMode = "auto")
    useEffect(() => {
        if (searchMode === "auto") {
            toolkitprops?.searchProps.onSearch(inputValue);
        }
    }, [inputValue, searchMode, toolkitprops]);

    const handleSearch = () => {
        toolkitprops?.searchProps.onSearch(inputValue); // Gọi onSearch trong "button" mode
    };

    const handleClear = () => {
        setInputValue(""); // Reset giá trị
        inputRef.current.focus(); // Focus lại vào input
        toolkitprops?.searchProps.onSearch(""); // Reset tìm kiếm
        if (onClear) onClear(); // Gọi callback nếu được truyền
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        if (paginationProps && isResetPagination) paginationProps.page = 1
        setInputValue(value); // Cập nhật state
        if (onChange) onChange(value); // Gọi callback onChange nếu được truyền
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter" && searchMode === "button") {
            e.preventDefault();
            handleSearch(); // Thực hiện tìm kiếm khi nhấn Enter
        }
    };

    return (
        <SearchContainer>
            <InputContainer maxWidth={maxWidth}>
                <SearchIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path
                        d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                        stroke="#7E8EA4"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </SearchIcon>
                <Input
                    ref={inputRef}
                    type="text"
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={handleInputChange} // Gọi hàm khi giá trị input thay đổi
                    onKeyPress={handleKeyPress}
                />
                {inputValue && (
                    <ClearButton onClick={handleClear}>
                        <ClearIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
                            <path
                                d="M14.1666 5.83301L5.83331 14.1663M5.83331 5.83301L14.1666 14.1663"
                                stroke="#475467"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </ClearIcon>
                    </ClearButton>
                )}
            </InputContainer>
            {searchMode === "button" && (
                <SearchButton className="btn btn-primary btn-sm" onClick={handleSearch} disabled={loading}>
                    {loading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                    {textButton || "Search"}
                </SearchButton>
            )}
        </SearchContainer>
    );
};

export default SearchCustomForTable;