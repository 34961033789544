import React, { useState } from 'react'
import { QuestionBankReview } from '@components/teachV2/QuestionBankDetail';
import { useLocation } from 'react-router-dom';

const QuestionBankDetail = () => {
  const location = useLocation();
  const [dataQuestionDetail, setDataQuestionDetail] = useState(JSON.parse(location?.state?.question))

  return (
    <div className="wrapper -teachV2HomePage pb-0">
      <QuestionBankReview 
        dataQuestionDetail={dataQuestionDetail}
        setDataQuestionDetail={setDataQuestionDetail}
        type={location?.state?.type}
        permission={location?.state?.permission}
      />
    </div>
  )
}

export default QuestionBankDetail
